import React from "react"

import Button from "../Button/button"

import CVLogo from "../../resources/svg/cv.svg"
import GitLogo from "../../resources/svg/git.svg"
import LinkedinLogo from "../../resources/svg/linkedin.svg"

import "./network-links.css"

const NetworkLinks = ({}) => {
  return (
    <div className="raphael-network-links">
      <Button
        action={() => {
          window.open("/raphael_meier.pdf", "_blank")
        }}
        Logo={CVLogo}
      ></Button>
      <Button
        action={() => {
          window.open("https://linkedin.com/in/meierraphael", "_blank")
        }}
        Logo={LinkedinLogo}
      ></Button>
      <Button
        action={() => {
          window.open("https://gitlab.com/raphael.meier", "_blank")
        }}
        Logo={GitLogo}
      ></Button>
    </div>
  )
}

export default NetworkLinks
