import React from "react"
import PropTypes from "prop-types"

import Button from "../Button/button"

import "./menu.css"

const Menu = ({ currentPage, setCurrentPage }) => {
  const resetMenu = page => {
    if (page !== "presentation") {
      setResetPresentation(true)
    }
    if (page !== "projects") {
      setResetProjects(true)
    }
    if (page !== "competencies") {
      setResetCompetencies(true)
    }
  }

  const changePage = page => {
    if (currentPage === page) {
      // Unclick a menu button makes you go to the home
      setCurrentPage("default")
    } else {
      setCurrentPage(page)
      resetMenu(page)
    }
  }

  const [resetPresentation, setResetPresentation] = React.useState(false)
  const [resetProjects, setResetProjects] = React.useState(false)
  const [resetCompetencies, setResetCompetencies] = React.useState(false)

  return (
    <div className="raphael-menu">
      <Button
        reset={resetPresentation}
        resetCallback={() => setResetPresentation(false)}
        text="PRESENTATION"
        switchMode={true}
        action={changePage.bind(this, "presentation")}
      ></Button>
      <Button
        reset={resetProjects}
        resetCallback={() => setResetProjects(false)}
        text="PROJECTS"
        switchMode={true}
        action={changePage.bind(this, "projects")}
      ></Button>
      <Button
        reset={resetCompetencies}
        resetCallback={() => setResetCompetencies(false)}
        text="COMPETENCIES"
        switchMode={true}
        action={changePage.bind(this, "competencies")}
      ></Button>
    </div>
  )
}

Menu.propTypes = {
  currentPage: PropTypes.string,
  setCurrentPage: PropTypes.func,
}

export default Menu
