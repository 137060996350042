import React from "react"

import Project from "../../components/Project/project"

import "./projects.css"

const Projects = ({}) => {
  return (
    <div className="raphael-projects">
      <Project
        title="Itsfire"
        store_link="https://play.google.com/store/apps/details?id=io.itsfire.release"
      >
        It is a social network application based on event location. It is
        developed with Ionic 5 and the back-end is in Django.
      </Project>
      <Project
        title="Euromillions Calculator"
        git_link="https://gitlab.com/raphael.meier/euromillions-calculator-2000"
      >
        This a good base for my C++ projects. It follows the Google C++ coding
        guidelines, there is unit testing, continuous integration, building with
        CMake and it even could be dockerised!
      </Project>
      <Project
        title="DFV Sensor"
        git_link="https://gitlab.com/raphael.meier/deepfuckingvalue-sensor"
      >
        This is a good base for a Python proof of concept. It watches a Reddit
        user by the API and if there is a new post, it makes calls to a list of
        number phones with Twilio.
      </Project>
      <Project
        title="Nekfapp"
        store_link="https://play.google.com/store/apps/details?id=com.nekfapp"
      >
        A mobile application developed in React Native.
        It helps you to find a word inside the lyrics of the french rapper Nekfeu. 
      </Project>
      <Project
        title="Dofbot"
        git_link="https://gitlab.com/raphael.meier/dofbot"
      >
        Dofbot is a proof of concept for a bot in Python on the video game
        Dofus. For now, it can fish. It uses pyautogui for automation and OpenCV
        for image analysis.
      </Project>
      <Project
        title="Websites"
        git_link="https://gitlab.com/raphael.meier/blank-docker-website"
      >
        With this Docker project, you can up an HTTPS WordPress really fast.
        These websites are examples of this project:
        <ul>
          <li>
            <a href="https://itsfire.io" target="_blank" rel="noreferrer">
              itsfire.io
            </a>
          </li>
          <li>
            <a href="https://vdg-avocats.net" target="_blank" rel="noreferrer">
              vdg-avocats.net
            </a>
          </li>
          <li>
            <a
              href="https://galerie-meier.com"
              target="_blank"
              rel="noreferrer"
            >
              galerie-meier.com
            </a>
          </li>
        </ul>
      </Project>
    </div>
  )
}

export default Projects
