import React from "react"

import Helmet from "react-helmet"

import "../styles/main.css"
import "../styles/responsive.css"

import Menu from "../components/Menu/menu"
import NeonLine from "../components/NeonLine/neon-line"
import NetworkLinks from "../components/NetworkLinks/network-links"

import Competencies from "../sections/Competencies/competencies"
import Presentation from "../sections/Presentation/presentation"
import Projects from "../sections/Projects/projects"

const Home = ({}) => {
  const [currentPage, setCurrentPage] = React.useState("default")

  const displaySection = () => {
    switch (currentPage) {
      case "competencies":
        return <Competencies></Competencies>
      case "presentation":
        return <Presentation></Presentation>
      case "projects":
        return <Projects></Projects>

      default:
        return null
    }
  }

  return (
    <>
      <Helmet>
        <html />
        <title>Raphael Meier</title>
        <meta name="description" content="The personal website of a software engineer." />
      </Helmet>
      <div className="main-grid">
        <div className="profile">
          <h1 className="profile-title">
            <span className="firstname">Raphael</span>
            <span className="lastname">Meier</span>
          </h1>
          <div className="profile-subtitle">
            <NeonLine animated={true} pixelHeight={10}></NeonLine>
            <NetworkLinks></NetworkLinks>
          </div>
        </div>
        <Menu currentPage={currentPage} setCurrentPage={setCurrentPage}></Menu>
        <div className="raphael-image main-picture">
          <div className="image animated-border"></div>
        </div>
        <div className="section">{displaySection()}</div>
      </div>
    </>
  )
}

export default Home
