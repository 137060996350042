import React from "react"

import "./competencies.css"

const Competencies = ({}) => {
  return (
    <div className="raphael-competencies">
      <div className="competency">C++</div>
      <div className="competency">Python</div>
      <div className="competency">Docker</div>
      <div className="competency">React</div>
      <div className="competency">React Native</div>
      <div className="competency">HTML - CSS - JS</div>
      <div className="competency">GitLab CI/CD</div>
      <div className="competency">Angular</div>
      <div className="competency">WordPress</div>
      <div className="competency">Database management and conception</div>
      <div className="competency">Django</div>
    </div>
  )
}

export default Competencies
