import React from "react"
import PropTypes from "prop-types"

import "./button.css"

const Button = ({
  action,
  Logo = null,
  text = "",
  stateDisabled = false,
  switchMode = false,
  reset,
  resetCallback,
}) => {
  const clickedClass = "clicked"
  const disabledClass = "disabled"
  const animationDuration = 0.6

  const [clicked, setClicked] = React.useState(false)
  const [disabled, setDisabled] = React.useState(stateDisabled)
  const animation_timeout = React.useRef(null)

  React.useEffect(() => {
    if (reset && switchMode) {
      resetCallback()
      setClicked(false)
      animation_timeout.current = setTimeout(() => {
        animation_timeout.current = null
      }, animationDuration * 1000)
    }
  })

  const handleClick = event => {
    if (!switchMode) {
      if (!disabled && !animation_timeout.current) {
        setClicked(true)
        animation_timeout.current = setTimeout(() => {
          setClicked(false)
          animation_timeout.current = setTimeout(() => {
            if (action) {
              action()
            }
            animation_timeout.current = null
          }, animationDuration * 1000)
        }, animationDuration * 1000)
      }
    } else {
      if (!disabled && !animation_timeout.current) {
        if (clicked) {
          setClicked(false)
        } else {
          setClicked(true)
        }
        action()
        animation_timeout.current = setTimeout(() => {
          animation_timeout.current = null
        }, animationDuration * 1000)
      }
    }
  }

  return (
    <button
      className={`raphael-button${clicked ? " " + clickedClass : ""}${
        disabled ? " " + disabledClass : ""
      }`}
      onClick={handleClick}
      style={{
        transition: `box-shadow ${animationDuration}s cubic-bezier(.79,.21,.06,.81)`,
      }}
    >
      {Logo ? <Logo className="logo" /> : null}
      {text !== "" ? <span className="text">{text}</span> : null}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  Logo: PropTypes.func,
  text: PropTypes.string,
  action: PropTypes.func,
  reset: PropTypes.bool,
  resetCallback: PropTypes.func,
}

export default Button
