import React from "react"
import PropTypes from "prop-types"

import "./neon-line.css"

const NeonLine = ({
  pixelHeight = 5,
  lighted = true,
  animated = false,
  lightedPercentage = 100,
}) => {
  return (
    <div
      className={`raphael-neon-line${lighted ? " lighted" : ""}${
        animated ? " animated" : ""
      }`}
      style={{ height: pixelHeight + "px", borderRadius: pixelHeight + "px" }}
    ></div>
  )
}

NeonLine.propTypes = {
  pixelHeight: PropTypes.number,
  lighted: PropTypes.bool,
  animated: PropTypes.bool,
  lightedPercentage: PropTypes.number,
}

export default NeonLine
