import React from "react"

import "./presentation.css"

const Presentation = ({}) => {
  return (
    <div className="raphael-presentation">
      Hi, I'm a freelance software engineer and I made this website to try the neumorphic
      design. <br/><br/>
      If you want to contact me for professional inquiries, feel free to download my resume or contact me on LinkedIn.
    </div>
  )
}

export default Presentation
