import React from "react"
import PropTypes from "prop-types"

import "./project.css"

import GitLogo from "../../resources/svg/git.svg"
import StoreLogo from "../../resources/svg/playstore.svg"

const Project = ({ title = "", git_link = "", store_link = "", children }) => {
  return (
    <div className="raphael-project">
      <h2>{title}</h2>
      <div className="project-description">{children}</div>
      <div className="external-link">
        {store_link ? (
          <a href={store_link} target="_blank" rel="noreferrer">
            <StoreLogo className="external-logo" />
          </a>
        ) : null}
        {git_link ? (
          <a href={git_link} target="_blank" rel="noreferrer">
            <GitLogo className="external-logo" />
          </a>
        ) : null}
      </div>
    </div>
  )
}

Project.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  git_link: PropTypes.string,
}

export default Project
